import { html, render } from "lit";
import ApplicationState from "applicationstate";
import { easepick, TimePlugin, LockPlugin } from '@easepick/bundle';
import ModalBase from './util/modal-base';
//import { getISODateStringWithoutTime } from "../lib/lib-date";
import { getCurrentClientId } from "../lib/lib-user";
import directus from "../lib/lib-directus";
import { format } from "date-fns";
import { ht } from "date-fns/locale";
import { getOrCreateAdhocSchedule } from "../lib/lib-schedule";

/**
 * @param {object} patient
 */
export default class AppTaskSelfCheckModal extends ModalBase {
    constructor() {
        super();
        this.form_design_options = [];
        this.form_design_id = null;
        //this.description = null;
        this.scheduled_date = null;
        this.messages = [];
        this.type = "self";
        this.patient = [];
        this.isConfirmed = false;
    }

    connectedCallback() {
        const types = {
            "self": "Self-Check",
            "general": "General",
            "education": "Education",
        }
        const title = types[this.type];

        this.template = () => {
            return html`
                <style>
                #description_textarea {
min-height: 120px;
                }
                .modal-body label.form-label span {
    font-size: 12px;
    text-align: right;
    right: -85px;
    position: relative;
    top: 0px;
}
#description_textarea::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #797d85;
}
#description_textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #797d85;
    opacity: 1;
}
#description_textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #797d85;
    opacity: 1;
}
#description_textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #797d85;
}
#description_textarea::placeholder { /* Standard Pseudo-class */
    color: #797d85;
}

                    #deactivate-patient-modal .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    #deactivate-patient-modal .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    #deactivate-patient-modal select {
                        cursor: pointer;
                    }
                    #deactivate-patient-modal .input-group:last-of-type {
                        margin-top: 12px;
                    }
                    .time-plugin-container select {
                        font-size: 18px;
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered" id="deactivate-patient-modal">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Schedule a ${title} Text Message</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            
                        </div>

                        <form id="deactivate_patient_form" novalidate>
                            <div class="modal-body">
                                <div class="input-wrapper mb-3"> 
                                    <label for="title_input" class="form-label">Task Type/Category</label>
                                    <input readonly disabled value=${title} id="title_input" class="form-control" aria-label="Task Title"/>
                                                    
                                                                   </div>
                                                                    <div class="input-wrapper mb-3">
                                    <input type="checkbox" @change=${e => this.handleSendNowChange()} id="sendNowCheckbox"> Send Now 
                                                                    </div>
                                                                    <div class="input-wrapper mb-3">
                                    <label for="date_input" class="form-label">Scheduled Date</label>
                                    <input @focus=${e => e.target.blur()} id="date_input" class="form-control" style="cursor: pointer;" aria-label="Scheduled Date" required/>
                                    <div class="invalid-feedback">
                                        Please select a date.
                                    </div>
                                </div>
                                ${this.type == "self" ? html`
                                    <div class="mb-3">
                                        <label for="form_select" class="form-label">Self-Check Path</label>
                                        <select @change=${e => this.handleFormDesignChange(e)} id="form_select" class="form-select" aria-label="Form Design" required>
                                            <option selected></option>
                                            ${this.form_design_options.map(form_design => html`
                                                <option value=${form_design.id}>${form_design.title}</option>
                                            `)}
                                        </select>
                                        <div class="invalid-feedback">
                                            Please select a path.
                                        </div>
                                    </div>` : null
                }

                                <div class="mb-3">
<label for="message_select" class="form-label">Customize your message:
</label>
<select @change=${e => this.handleMessageChange(e)} id="message_select" class="form-select mb-2" aria-label="Description">
    <option selected></option>
    ${this.messages.map(msg => html`
        <option value=${msg.template_content}>${msg.template_name}</option>
    `)}
</select>
<textarea @change=${e => this.handleMessageChange(e)} id="description_textarea" class="form-control" aria-label="Description">
Hey there! This is your care navigator and I would love to know how you're doing. Click the link and let's get started. 😀
</textarea>
${this.type == "self" ? html`<span>** link will be appended to the end</span>` : ''}
                                </div>
                                <div class="input-wrapper">
                                <input type="checkbox" id="confirm" style="margin-right: 5px;" @click=${e => this.handleCheckboxClick(e)}/>
                                Please confirm that the text message does not contain any patient health information (PHI).
                                </div>
                            </div>
                            <div class="modal-footer">
                               
                                <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
Cancel
                                </button>
                                <button
type="button"
class="btn btn-primary modal-confirm-btn"
style="color: white; background-color: var(--t-color-primary); border-color: var(--t-color-primary);"
id="confirm-button"
disabled
@click=${(_e) => this.handleConfirmClick()}>
Confirm
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            `;
        };
        this.render();
        this.addEventListener("shown.bs.modal", this.init);
        const descriptionTextarea = this.querySelector('#description_textarea');
        if (descriptionTextarea) {
            this.description = descriptionTextarea.value;
        }
    }

    handleCheckboxClick(e) {
        this.querySelector('#confirm-button').disabled = this.isConfirmed;
        this.isConfirmed = !this.isConfirmed;
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    async init() {
        this.datepicker = new easepick.create({
            element: this.querySelector('#date_input'),
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
                'css/easepick.css'
            ],
            autoApply: false,
            readonly: false,
            firstDay: 0,
            date: this.date,
            plugins: [TimePlugin, LockPlugin],
            TimePlugin: {
                format12: true,
                stepMinutes: 15,

            },
            LockPlugin: {
                minDate: new Date(),
            }

        });
        const default_time = ApplicationState.get("app.clientSettings.default_sms_time");

        // Function to ensure time is in HH:MM:SS format
        function formatTime(time) {
            if (time && time.length === 5) { // Checks if the time is in HH:MM format
                return time + ":00"; // Append :00 to make it HH:MM:SS
            }
            return time; // Return the original if it's already in HH:MM:SS format or undefined
        }

        const formatted_time = formatTime(default_time) || "11:00:00";

        console.log('default_time', formatted_time);

        this.datepicker.setTime(formatted_time);
        this.datepicker.on("select", this.handleDateChange.bind(this));

        if (this.date && formatted_time) {
            const timeParts = formatted_time.split(':');  // Split the time string into components
            if (timeParts.length === 3) {  // Ensure there are three parts: hours, minutes, seconds
                const hours = parseInt(timeParts[0], 10);
                const minutes = parseInt(timeParts[1], 10);
                const seconds = parseInt(timeParts[2], 10);

                this.date.setHours(hours, minutes, seconds);  // Set the hours, minutes, and seconds
            }

            const utcDate = new Date(this.date.getTime() + this.date.getTimezoneOffset() * 60000);
            this.scheduled_date = format(utcDate, "yyyy-MM-dd HH:mm:ss");
        }


        const dateInput = this.querySelector('#date_input');
        dateInput.value = (this.date) ? format(this.date, "EEEE, MMM d, yyyy h:mm a") : "";

        if (this.type == "self") {
            const forms_result = await directus.items("form_design").readByQuery({
                filter: {
                    category: "self",
                    status: "published",
                }
            })
            this.form_design_options = forms_result.data;
        }
        if (this.type == "self") {
            this.type == "checkin";
        }
        //console.log('************client_id', getCurrentClientId());
        const messages_result = await directus.items("sms_templates").readByQuery({
            filter: {
                template_type: this.type,
                status: "published",
                client_id: getCurrentClientId()
            }
        })
        this.messages = messages_result.data;
        this.render();
    }

    async handleConfirmClick() {
        const form_element = this.querySelector('#deactivate_patient_form');
        form_element.classList.add('was-validated');
        const valid = form_element.checkValidity();
        if (valid) {
            // Get or create schedule first
            const scheduleId = await getOrCreateAdhocSchedule(this.patient, this.scheduled_date);

            if (this.type == "self") {
                const survey = await directus.items("hh_patient_reported_measures").createOne({
                    client_id: this.patient.client_id,
                    patient_id: this.patient.id,
                    episode_id: this.patient.current_episode_id,
                    status: "draft",
                    survey_date: this.scheduled_date,
                });

                await directus.items("task").createOne({
                    assigned_user_id: this.patient.engagement_specialist,
                    client_id: this.patient.client_id,
                    episode_id: this.patient.current_episode_id,
                    patient_id: this.patient.id,
                    patient_schedule_id: scheduleId,
                    survey_id: survey.id,
                    form_design_id: this.form_design_id,
                    scheduled_date: this.scheduled_date,
                    //completion_risk: 5,
                    status: "ready",
                    survey_type: "hh_patient_reported_measures",
                    task_type: "self",
                    title: "Self Check",
                    description: this.description,
                    is_prn_checkpoint: true,
                });
            } else {
                await directus.items("patient_communication").createOne({
                    patient_id: this.patient.id,
                    client_id: this.patient.client_id,
                    patient_schedule_id: scheduleId,
                    communication_type: "sms",
                    notes: this.type,
                    caller_type: "staff",
                    sms_message: this.description,
                    status_reason: "scheduled",
                    status: "ready",
                    scheduled_date: this.scheduled_date,
                });
            }
            this.dismiss({ confirmed: true });
            document.querySelector("app-toaster").toast("Task created successfully.")
        }
    }

    handleDateChange(e) {
        const date = e.detail.date;
        // Convert local date to UTC
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        this.scheduled_date = format(utcDate, "yyyy-MM-dd HH:mm:ss");
        //console.log(this.scheduled_date);
        const dateInput = this.querySelector('#date_input');
        dateInput.value = (date) ? format(date, "EEEE, MMM d, yyyy h:mm a") : "";
    }

    handleSendNowChange() {
        if (sendNowCheckbox.checked) {
            const now = new Date();
            // Convert local date to UTC
            const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
            const formatted = format(utcNow, 'yyyy-MM-dd HH:mm:ss');
            this.scheduled_date = formatted;
            //(this.scheduled_date);
            this.querySelector('#date_input').value = (now) ? format(now, "EEEE, MMM d, yyyy h:mm a") : "";
            this.querySelector('#date_input').setAttribute('disabled', '');
        } else {
            this.querySelector('#date_input').removeAttribute('disabled', '');
        }
    }

    handleFormDesignChange(e) {
        this.form_design_id = e.target.value;
    }

    handleMessageChange(e) {
        this.description = e.target.value;
        const description = this.querySelector('#description_textarea');
        description.value = this.description;
    }
}

customElements.define("app-task-self-check-modal", AppTaskSelfCheckModal);
