import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
//import BootstrapTable from "bootstrap-table/dist/bootstrap-table";
import DataDefinition from "shared/lib/lib-data-definition";
import { Dropdown } from "bootstrap";
import { ref, createRef } from "lit/directives/ref.js";
import { getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";
import { noteCellFormatter } from "../util/lib-report";
import {
  format,
  parseISO,
  differenceInDays,
  startOfMonth,
  endOfMonth,
  addDays,
  isSameDay,
  subDays,
  subMonths,
} from "date-fns";
import { TabulatorFull as Tabulator } from "tabulator-tables";

import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";
import ApexCharts from "apexcharts";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import interactionPlugin from "@fullcalendar/interaction";
import "bootstrap/dist/css/bootstrap.css";

export default class ReportPatientOasisAlt extends HTMLElement {
  static get config() {
    return {
      icon: "personal_injury",
      report: "oasis alrt",
      title: "Oasis Transfers Alt",
      description: "Oasis and Transfers.",
      component: ReportPatientOasisAlt,
    };
  }
  get status_filter() {
    return this._status_filter;
  }

  set status_filter(value) {
    this._status_filter = value;
  }
  set filter_state(value) {
    this._filter_state = value;
  }
  /** @type {import('shared/components/filter/app-filter').FilterState} */
  get filter_state() {
    return this._filter_state;
  }

  set selected_columns(value) {
    this._selected_columns = value;
  }

  get selected_columns() {
    return this._selected_columns;
  }

  set table_columns(value) {
    this._table_columns = value;
  }

  get table_columns() {
    return this._table_columns;
  }

  constructor() {
    super();
    this.hospitalTransfers = [];
    this.usePast90DaysFilter = true;
    const filter_string = new URL(location.href).searchParams?.get("filters");
    const { fromDate, toDate } = this.defaultFilterDates();
    this.filters = JSON.parse(filter_string) || {
      oasis_date_range: `${fromDate},${toDate}`,
    };
    this.startDate = new Date(fromDate);
    this.endDate = new Date(toDate);
  }

  connectedCallback() {
    this.render();
    //this.init();
    this.loadVisits();

    //this.addEventListener('click', event => { // listner for toggle all rows buttons expand/collapse (not being used)
    //    if (event.target.id === 'toggle-all-rows-btn') {
    //        this.expandCollapseAllRows('expand');
    //    }
    //});
  }

  async loadVisits() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    try {
      if (!(this.startDate instanceof Date && !isNaN(this.startDate))) {
        console.error("Invalid startDate:", this.startDate);
        return;
      }

      if (!(this.endDate instanceof Date && !isNaN(this.endDate))) {
        console.error("Invalid endDate:", this.endDate);
        return;
      }

      const startDate = format(this.startDate, "yyyy-MM-dd");
      const endDate = format(this.endDate, "yyyy-MM-dd");

      const response = await directus.items("patient").readByQuery({
        fields: [
          "id",
          "first_name",
          "last_name",
          "visits.visit_description",
          "visits.visit_date",
          "visits.visit_status",
          "visits.careteam_member_id.first_name",
          "visits.careteam_member_id.last_name",
          "tasks.status",
          "tasks.scheduled_date",
          "tasks.completed_date",
          "tasks.survey_id",
        ],
        filter: {
          _and: [
            {
              client_id: {
                _eq: getCurrentClientId(),
              },
            },
            {
              last_task_id: {
                status: {
                  _eq: "complete",
                },
              },
            },
            {
              visits: {
                visit_description: {
                  _contains: "OASIS",
                },
              },
            },
            {
              visits: {
                visit_description: {
                  _contains: "Transfer",
                },
              },
            },
            {
              visits: {
                visit_date: {
                  _between: [startDate, endDate],
                },
              },
            },
          ],
        },
        sort: ["-visits.visit_date"],
      });

      console.log("API Response:", response);

      if (response && response.data) {
        console.log("Loaded patients with visits:", response.data);
        this.processVisits(response.data);
        this.isLoading = false;
        return response.data;
      } else {
        console.error("Invalid API response:", response);
        throw new Error("Invalid API response");
      }
    } catch (error) {
      console.error("Error loading visits:", error);
      throw error;
    }
  }

  processVisits(patients) {
    this.hospitalTransfers = patients.flatMap((patient) => {
      const oasisTransferVisits = patient.visits.filter(
        (visit) =>
          visit.visit_description.includes("OASIS") && visit.visit_description.includes("Transfer")
      );

      const nonOasisVisits = patient.visits.filter(
        (visit) => !visit.visit_description.includes("OASIS")
      );

      return oasisTransferVisits.map((oasisVisit) => {
        const mostRecentNonOasisVisit = nonOasisVisits.sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )[0];

        return {
          ...oasisVisit,
          patient_id: patient.id,
          patient_first_name: patient.first_name,
          patient_last_name: patient.last_name,
          tasks: patient.tasks,
          last_non_oasis_visit: mostRecentNonOasisVisit,
          days_since_last_non_oasis_visit: mostRecentNonOasisVisit
            ? differenceInDays(
              new Date(oasisVisit.visit_date),
              new Date(mostRecentNonOasisVisit.visit_date)
            )
            : "N/A",
        };
      });
    });

    //this.renderTransfersByDayChart(this.hospitalTransfers);
    this.renderTransfersTable(patients);
    //this.renderHeatmap(this.hospitalTransfers);
    //this.renderCalendar(this.hospitalTransfers);
    //this.renderCalendarh(this.hospitalTransfers);
  }

  renderTransfersTable(patients) {
    console.log("Patients:", patients);
    const tableOptions = {
      data: this.hospitalTransfers,
      rowClick: (event, row) => {
        const rowData = row.getData();
        const visitDate = rowData.visit_date;
        this.highlightEvent(visitDate);
      },
      layout: "fitColumns",
      columns: [
        {
          title: "Patient",
          formatter: (cell, formatterParams, onRendered) => {
            const rowData = cell.getRow().getData();
            return `${rowData.patient_last_name}, ${rowData.patient_first_name}`;
          },
        },
        {
          title: "Visit Date",
          field: "visit_date",
          formatter: (cell, formatterParams, onRendered) => {
            const visitDate = cell.getValue();
            return visitDate
              ? format(new Date(visitDate), "MM/dd/yyyy")
              : "N/A";
          },
        },
        {
          title: "Care Team Member",
          formatter: (cell, formatterParams, onRendered) => {
            const rowData = cell.getRow().getData();
            const firstName =
              rowData.careteam_member_id?.first_name || "Unknown";
            const lastName = rowData.careteam_member_id?.last_name || "Unknown";
            return `${firstName} ${lastName}`;
          },
        },
        {
          title: "Description",
          field: "visit_description",
        },
        {
          title: "Status",
          field: "visit_status",
        },
        {
          title: "Days Since CP",
          formatter: (cell, formatterParams, onRendered) => {
            const rowData = cell.getRow().getData();
            const completedTasks = rowData.tasks.filter(
              (task) => task.status === "complete"
            );
            const mostRecentCompletedTask = completedTasks.sort(
              (a, b) => new Date(b.completed_date) - new Date(a.completed_date)
            )[0];
            if (mostRecentCompletedTask) {
              const daysDifference = differenceInDays(
                new Date(rowData.visit_date),
                new Date(mostRecentCompletedTask.completed_date)
              );
              return daysDifference;
            }
            return "N/A";
          },
        },
        {
          title: "Days Since Last Actual Visit",
          formatter: (cell, formatterParams, onRendered) => {
            const rowData = cell.getRow().getData();
            return rowData.days_since_last_non_oasis_visit;
          },
        },
        {
          title: "Checkpoint Detail",
          formatter: (cell, formatterParams, onRendered) => {
            const rowData = cell.getRow().getData();
            const patientId = rowData.patient_id;
            const completedTasks = rowData.tasks.filter(
              (task) => task.status === "complete"
            );
            const mostRecentCompletedTask = completedTasks.sort(
              (a, b) => new Date(b.completed_date) - new Date(a.completed_date)
            )[0];
            if (mostRecentCompletedTask) {
              const checkpointDetailUrl = `/client/patients/${patientId}/checkpoints/${mostRecentCompletedTask.survey_id}`;
              return `<a class="oasis-cp-detail-btn" href="${checkpointDetailUrl}" target="_blank">View Checkpoint</a>`;
            }
            return "N/A";
          },
        },
      ],
      rowSelected: (row) => {
        const rowData = row.getData();
        const selectedDate = new Date(rowData.visit_date);
        this.highlightChartPoint(selectedDate);
      },
    };

    this.table = new Tabulator("#transfersTable", tableOptions);
  }

  renderTransfersByDayChart(transfers) {
    const currentDate = new Date();
    const startOfMonthDate = startOfMonth(currentDate);
    const endOfMonthDate = endOfMonth(currentDate);
    const daysInMonth = differenceInDays(endOfMonthDate, startOfMonthDate) + 1;

    const transferCounts = Array(daysInMonth).fill(0);
    const categories = Array.from({ length: daysInMonth }, (_, i) =>
      format(addDays(startOfMonthDate, i), "MM/dd")
    );

    transfers.forEach((transfer) => {
      const transferDate = new Date(transfer.visit_date);
      const transferDay = differenceInDays(transferDate, startOfMonthDate);
      transferCounts[transferDay]++;
    });

    const chartOptions = {
      series: [
        {
          name: "Transfers",
          data: transferCounts,
        },
      ],
      chart: {
        type: "line",
        height: 350,
        events: {
          dataPointMouseEnter: (event, chartContext, config) => {
            const pointIndex = config.dataPointIndex;
            this.highlightTableRows(pointIndex);
          },
        },
      },
      xaxis: {
        categories: categories,
        title: {
          text: "Day",
        },
      },
      yaxis: {
        title: {
          text: "Number of Transfers",
        },
      },
    };

    const chart = new ApexCharts(
      document.querySelector("#transfersByDayChart"),
      chartOptions
    );
    chart.render();
  }

  renderCalendar(transfers) {
    const calendarEl = document.getElementById("calendar");
    const calendar = new Calendar(calendarEl, {
      plugins: [bootstrap5Plugin, dayGridPlugin, multiMonthPlugin],
      initialView: "dayGridYear",
      themeSystem: "bootstrap5",
      headerToolbar: {
        left: "",
        center: "",
        right: "",
      },
      events: transfers.map((transfer) => ({
        title: `${transfer.patient_last_name}, ${transfer.patient_first_name}`,
        start: transfer.visit_date,
        allDay: true,
        color: "#F29188",
        textColor: "#000000",
      })),
      eventDidMount: (info) => {
        info.el.title = info.event.title;
      },
      eventClick: (info) => {
        const selectedDate = new Date(info.event.start);
        this.highlightTableRows(selectedDate);
        this.highlightChartPoint(selectedDate);
      },
    });
    calendar.render();
  }

  renderCalendarh(transfers) {
    const calendarEl = document.getElementById("calendarh");
    const calendar = new Calendar(calendarEl, {
      plugins: [
        interactionPlugin,
        bootstrap5Plugin,
        dayGridPlugin,
        multiMonthPlugin,
      ],
      initialView: "multiMonthCustom",
      themeSystem: "bootstrap5",
      background: true,
      selectable: true,
      select: (info) => {
        // Handle date range selection
        this.startDate = info.start;
        this.endDate = info.end;
        this.loadVisits(); // Update data based on selected date range
      },
      initialDate: subMonths(new Date(), 1), // Set initial date
      dateClick: (info) => {
        // Handle date selection
        const selectedDate = info.date;
        this.startDate = startOfMonth(selectedDate);
        this.endDate = endOfMonth(selectedDate);
        this.loadVisits(); // Update data based on selected date range
      },
      eventClick: (info) => {
        const visitDate = info.event.start;
        this.highlightEvent(visitDate);
      },
      //multiMonthMaxColumns: 12,
      //height: 100,
      //contentHeight: 100,
      //aspectRatio: 2,
      dayHeaderFormat: { weekday: "narrow" },
      customButtons: {
        myPrevButton: {
          text: "",
          click: function () {
            calendar.prev();
          },
        },
        myNextButton: {
          text: "",
          click: function () {
            calendar.next();
          },
        },
      },
      views: {
        multiMonthCustom: {
          type: "multiMonth",
          duration: { months: 7 },
          columnFormat: {
            month: "dd",
          },
          //height: 100,
          //contentHeight: 100
        },
      },
      headerToolbar: {
        left: "myPrevButton",
        center: "",
        right: "myNextButton",
      },
      events: transfers.map((transfer) => ({
        title: `${transfer.patient_last_name}, ${transfer.patient_first_name}`,
        start: transfer.visit_date,
        allDay: true,
        display: "background",
        color: "#c97972",
        textColor: "#ffffff",
      })),
      eventDidMount: (info) => {
        info.el.title = info.event.title;
      },
      eventClick: (info) => {
        const selectedDate = new Date(info.event.start);
        this.highlightTableRows(selectedDate);
        this.highlightChartPoint(selectedDate);
      },
    });
    calendar.render();
  }

  renderHeatmap(transfers) {
    const startDate = this.startDate;
    const endDate = this.endDate;

    const transferCounts = {};
    transfers.forEach((transfer) => {
      const transferDate = format(new Date(transfer.visit_date), "yyyy-MM-dd");
      transferCounts[transferDate] = (transferCounts[transferDate] || 0) + 1;
    });

    const series = [];
    let currentIterationDate = startDate;
    while (currentIterationDate <= endDate) {
      const formattedDate = format(currentIterationDate, "yyyy-MM-dd");
      const count = transferCounts[formattedDate] || 0;
      series.push({
        x: formattedDate,
        y: count,
      });
      currentIterationDate = addDays(currentIterationDate, 1);
    }

    const heatmapOptions = {
      series: [
        {
          name: "Transfers",
          data: series,
        },
      ],
      chart: {
        height: 100,
        offsetY: -10,
        width: "100%",
        type: "heatmap",
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const selectedDate = new Date(
              config.w.config.xaxis.categories[config.dataPointIndex]
            );
            this.highlightTableRows(selectedDate);
            this.highlightChartPoint(selectedDate);
          },
        },
      },
      dataLabels: {
        enabled: true,
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
        //dropShadow: {
        //    enabled: true,
        //    top: 1,
        //    left: 1,
        //    blur: 1,
        //    color: '#000',
        //    opacity: 0.45
        //}
      },
      grid: {
        padding: {
          bottom: 20,
        },
      },
      xaxis: {
        type: "datetime",
        min: startDate.getTime(),
        max: endDate.getTime(),
        labels: {
          format: "MMM dd",
          rotate: -45,
          rotateAlways: true,
          textAnchor: "middle",
          offsetX: "10",
          offsetY: -8,
          style: {
            colors: "gray",

            fontSize: "11px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        fill: {
          type: "solid",
          color: "#B1B9C4",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      fill: {
        type: "solid",
        color: "#B1B9C4",
        gradient: {
          colorFrom: "#D8E3F0",
          colorTo: "#BED1E6",
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          format: "MMM dd yyyy",
        },
      },
    };

    const heatmap = new ApexCharts(
      document.querySelector("#heatmap"),
      heatmapOptions
    );
    heatmap.render();
  }

  highlightTableRows(selectedDate) {
    const tableData = this.hospitalTransfers;
    tableData.forEach((row, index) => {
      const rowDate = new Date(row.visit_date);
      if (isSameDay(rowDate, selectedDate)) {
        this.table.selectRow(index);
      } else {
        this.table.deselectRow(index);
      }
    });
  }

  highlightEvent(visitDate) {
    // Highlight the corresponding event on the calendar
    const calendarApi = this.calendar.getApi();
    const events = calendarApi.getEvents();
    events.forEach((event) => {
      if (event.start.getTime() === visitDate.getTime()) {
        event.setProp("classNames", ["highlighted-event"]);
      } else {
        event.setProp("classNames", []);
      }
    });

    // Highlight the corresponding row in the table
    const tableData = this.table.getData();
    tableData.forEach((row) => {
      if (row.visit_date.getTime() === visitDate.getTime()) {
        row.toggleClass("highlighted-row");
      } else {
        row.toggleClass("highlighted-row", false);
      }
    });
  }

  highlightChartPoint(selectedDate) {
    const startOfMonthDate = startOfMonth(selectedDate);
    const pointIndex = differenceInDays(selectedDate, startOfMonthDate);
    this.chart.setSelection([{ dataPointIndex: pointIndex }]);
  }

  defaultFilterDates() {
    const curDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(curDate.getDate() - 30); // 30 days ago from current date.

    const fromDate = format(thirtyDaysAgo, "yyyy-MM-dd");
    const toDate = format(curDate, "yyyy-MM-dd");

    return {
      fromDate: fromDate,
      toDate: toDate,
    };
  }

  //handleDateFilterChange(e) {
  //    const { start, end } = e.detail;
  //    this.filters["oasis_date_range"] = `${getISODateStringWithoutTime(start)},${getISODateStringWithoutTime(end)}`;
  //    this.loadVisits();
  //}
  handleDateFilterChange(e) {
    const { start, end } = e.detail;
    this.startDate = start;
    this.endDate = end;
    this.filters["oasis_date_range"] = `${getISODateStringWithoutTime(
      start
    )},${getISODateStringWithoutTime(end)}`;
    console.log(start, end);
    this.loadVisits();
  }

  render() {
    render(
      html`
        <div
          class="oasis-date-picker-alt"
          style="align-items: center; margin: 0 0 0 16px;"
        >
          <app-report-filter
            .title=${"Date Reported"}
            .is_datepicker=${true}
            .value=${this.filters.oasis_date_range}
            @dateselect=${(e) => this.handleDateFilterChange(e)}
            @clear=${(_e) => this.handleFilterClear("oasis_date_range")}
          ></app-report-filter>
        </div>

        <div
          class="container-fluid oasis-transfers"
          style="height: 100%; padding: 0;"
        >
          <div class="row" style="padding: 0 25px 0 25px; margin-bottom: 16px;">
            <div class="cal-h-scroll" style="">
              <div id="calendarh"></div>
            </div>
          </div>
          <div class="row" style="padding: 0 25px 0 25px; margin-bottom: 16px;">
            <div class="col-12" style="">
              <div id="heatmap"></div>
            </div>
          </div>

          <div
            class="row"
            style="padding: 0; margin: 0 0 0 0"
            id="oasis-transfers-charts"
          >
            <div class="col-md-12">
              <div id="transfersTable"></div>
            </div>
            <!--  <div class="col-md-4">
                            <div id="calendar"></div>
                           
                            <div id="transfersByDayChart"></div>
                        </div> -->
          </div>
        </div>
      `,
      this
    );
  }
}

customElements.define("report-patient-oasis-alt", ReportPatientOasisAlt);
