import { html, nothing, render } from 'lit';
import ApplicationState from 'applicationstate';
import { navigate } from '../lib/lib-router';
import '../components/filter/app-filter';
//import '../components/filter/app-filter-data-view';
import '../components/filter/app-filter-data-view-server';
import '../components/filter/app-filter-saved-views';
import '../components/filter/app-filter-bar-graph';
import '../components/filter/app-filter-big-number';
import { getCurrentClientId } from '../lib/lib-user';
import "../components/filter/app-filter-csv-export";
import AppPatientProfile from '../components/patient/app-patient-profile';
import { format, parseISO, differenceInDays } from 'date-fns';
import { DateTime } from "luxon";
window.luxon = { DateTime };

export default class SceneInsightsPatientsPlus extends HTMLElement {
    set filter_state(value) {
        this._filter_state = value;
    }

    /** @type {import('shared/components/filter/app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;
    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    get selected_columns() {
        return this._selected_columns;
    }

    set view(value) {
        this._view = value;
        //this.render();
    }

    get view() {
        return this._view;
    }

    get options() {
        return this._options;
    }

    set options(value) {
        this._options = value;

    }
    set table_columns(value) {
        this._table_columns = value;
    }
    get table_columns() {
        return this._table_columns;
    }
    constructor() {
        super();

        this.countVisitsByDescription = (visits, descriptions) => {
            return visits.filter(visit => descriptions.includes(visit.visit_description)).length;
        };

        this.toggle = "";
        this._app_name = ApplicationState.get('app.staff.filter_state');
        //console.log("app name", this._app_name);
        this._options = {
            ajaxURL: "/items/patient",
            ajaxParams: {},
            sortMode: "remote",
            filterMode: "remote",
            //pagination: true,
            //paginationMode: "remote",
            //paginationSize: 25,
            paginationCounter: "rows",
            layout: "fitDataFill",
            progressiveLoad: "scroll",
            progressiveLoadScrollMargin: 300,
            responsiveLayout: "collapse",
            //responsiveLayoutCollapseStartOpen: this.toggle,
            alignEmptyValues: "bottom",
            initialSort: [{ column: "current_episode_start", dir: "desc" },],
            columnDefaults: {
                tooltip: false,
            },
            checkbox: false
        };
        this._selected_columns = [
            "status",
            "status_reason",
            "current_soc",
            "first_name",
            "last_name",
            "mrn",
            "primary_diagnosis",
            "current_episode_id.start_date",
            "current_episode_id.end_date",
            "episodes.status",
            "episodes.status_reason",
            "episodes.start_date",
            "episodes.end_date",
            "current_episode_start",
            "current_episode_end",
            "current_soc",
            "primary_payer",
            "engagement_specialist.last_name",
            "internal_memo",
            "source_data",
            "ext_referral",
            "referral_source",
            "visits.visit_date",
            "visits.visit_type",
            "visits.visit_status",
            "visits.visit_description",

        ];
        this._table_columns = [


            {
                formatter: "responsiveCollapse",
                field: "responiveIcon",
                headerSort: false,
                titleFormatter: function (cell) {

                    var isExpanded = false;

                    // Create a container for the toggle button
                    var container = document.createElement("div");
                    container.className = "tabulator-responsive-collapse-toggle-header";

                    // Add SVG for 'expand all' and 'collapse all'
                    container.innerHTML = `<svg class="tabulator-responsive-collapse-toggle-open" viewBox="0 0 24 24">
                                  <line x1="7" y1="12" x2="17" y2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
                                  <line y1="7" x1="12" y2="17" x2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
                               </svg>
                               <svg class="tabulator-responsive-collapse-toggle-close" viewBox="0 0 24 24" style="display: none;">
                                  <line x1="7" y1="12" x2="17" y2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
                               </svg>`;

                    // Event listener for the toggle
                    container.addEventListener("click", function () {
                        var table = cell.getTable();
                        table.getRows().forEach(function (row) {
                            var rowToggle = row.getElement().querySelector('.tabulator-responsive-collapse-toggle');
                            var rowContent = row.getElement().querySelector('.tabulator-responsive-collapse');

                            if (rowToggle && rowContent) {
                                if (isExpanded) {
                                    rowToggle.classList.remove('open');
                                    rowContent.style.display = 'none'; // Collapse the content
                                } else {
                                    rowToggle.classList.add('open');
                                    rowContent.style.display = ''; // Expand the content
                                }
                            }
                        });

                        container.querySelector('.tabulator-responsive-collapse-toggle-open').style.display = isExpanded ? '' : 'none';
                        container.querySelector('.tabulator-responsive-collapse-toggle-close').style.display = isExpanded ? 'none' : '';


                        isExpanded = !isExpanded;
                    });

                    return container;
                }

            },
            {
                title: "Status",
                field: "statuses",  // Make sure this is the correct field identifier
                minWidth: 80,
                sorter: "string",
                sorterParams: {
                    field: "status_reason"
                },
                formatter: function (cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let statusMappings = {
                        "import_approval_requested": "Pending",
                        "import_approved": "Approved",
                        "import_declined": "Declined"
                    };

                    // Check if the status exists in the mapping, otherwise use a default formatting
                    let statusText = statusMappings[rowData.status] || rowData.status.replace(/_/g, " ").replace("import ", "");

                    return statusText;
                }
            },

            //{
            //    title: "Previous Status",
            //    field: "status_reason",
            //    hozAlign: "center",
            //    formatter: function (cell, formatterParams) {
            //        const value = cell.getValue();
            //
            //        switch (value) {
            //            case "successfully_completed":
            //                return "Complete";
            //            case "requested_opt_out":
            //                let cellElement = cell.getElement();
            //                cellElement.style.backgroundColor = "#f7dfe0";
            //                cellElement.style.fontWeight = "bold";
            //                return "Opt-out";
            //            case "client_removed":
            //                return "Removed";
            //            default:
            //                return value;
            //        }
            //    },
            //    sorter: "string"
            //},


            //{
            //    name: "Agency",
            //    fields: ["client_id.name"],
            //    field: "client_id.name"
            //},
            {
                name: "Patient Name",
                fields: ["last_name", "first_name"],
                field: "name",
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getRow().getData();
                    const lastName = rowData.last_name;
                    const firstName = rowData.first_name;
                    const mrn = rowData.mrn;
                    const statusReason = rowData.status_reason;

                    let formattedName = "";

                    if (statusReason === "requested_opt_out") {
                        formattedName += '<span class="small-asterix">*</span> ';
                        cell.getElement().style.fontWeight = "bold";
                    }

                    formattedName += `${lastName}, ${firstName} - ${mrn}`;

                    return formattedName;
                },
                sorter: "string",
                responsive: 0,
                sorterParams: {
                    field: "last_name"
                },
                minWidth: 180,
            },
            {
                title: "Current Episode",
                field: "current_episode_start",
                minWidth: 265,
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getRow().getData();
                    const episodes = rowData.episodes;
                    const summary = document.createElement("div");

                    // Conditionally create and append expand button if episodes exist
                    if (Array.isArray(episodes) && episodes.length > 0) {
                        const expandBtn = document.createElement("button");
                        expandBtn.textContent = "+";
                        summary.appendChild(expandBtn);

                        // Event listener for expand/collapse
                        expandBtn.addEventListener("click", function () {
                            if (expandBtn.textContent === "+") {
                                expandBtn.textContent = "-";
                                // Render full list of episodes with status and reason
                                const episodesList = document.createElement("ul");
                                episodesList.classList.add("past-episodes");

                                episodes.slice().reverse().forEach(episode => {
                                    const listItem = document.createElement("li");
                                    const startDate = episode.start_date ? format(new Date(episode.start_date), "MM/dd/yy") : "(no start date)";
                                    const endDate = episode.end_date ? format(new Date(episode.end_date), "MM/dd/yy") : "(no end date)";
                                    const status = episode.status ? episode.status.replace(/_/g, " ").replace("import ", "") : "";
                                    const statusReason = episode.status_reason ? episode.status_reason.replace(/_/g, " ") : "";
                                    listItem.textContent = `${startDate} - ${endDate} | ${statusReason}`;
                                    episodesList.appendChild(listItem);
                                });
                                summary.appendChild(episodesList);
                            } else {
                                expandBtn.textContent = "+";
                                // Remove the full list of episodes
                                summary.removeChild(summary.lastChild);
                            }
                            cell.getRow().normalizeHeight(); // Update the row height
                        });
                    }

                    // Create summary text container for the initial date and count bubble

                    const currentStart = rowData.current_episode_start ? format(parseISO(rowData.current_episode_start), "MM/dd/yy") : "(no start date)";
                    const currentEnd = rowData.current_episode_end ? format(parseISO(rowData.current_episode_end), "MM/dd/yy") : "(no end date)";
                    //const currentStart = rowData.current_episode_start ? format(new Date(rowData.current_episode_start), "MM/dd/yyyy") : "(no start date)";
                    //const currentEnd = rowData.current_episode_end ? format(new Date(rowData.current_episode_end), "MM/dd/yyyy") : "(no end date)";
                    const summaryTextContainer = document.createElement("span");
                    summaryTextContainer.textContent = `${currentStart} - ${currentEnd}`;
                    summary.appendChild(summaryTextContainer);

                    // Append count bubble to summary text container if episodes exist
                    if (Array.isArray(episodes) && episodes.length > 0) {
                        const countBubble = document.createElement("span");
                        countBubble.classList.add("episode-count-bubble");
                        countBubble.textContent = ` ${episodes.length}`;
                        summaryTextContainer.appendChild(countBubble);
                    }

                    return summary;
                },



                variableHeight: true // Enable variable row height

            },

            {
                title: "SN Visits Episode Total",
                field: "sn_visits_total",
                headerVertical: true,
                formatter: function (cell) {
                    const rowData = cell.getRow().getData();
                    const visits = rowData.visits || [];
                    const snVisits = visits.filter(visit => visit.visit_type === "SN");
                    return snVisits.length;
                },
                sorter: "number"
            },

            {
                title: "SN Visits Episode Total",
                field: "sn_visits_total",
                headerVertical: true,
                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    return this.countVisitsByDescription(visits, [
                        "Skilled Nurse Visit", "LPN/LVN - Skilled Nursing Visit",
                        "RN - Skilled Nursing Visit", "Skilled Nurse Visit - LPN",
                        "Skilled Nurse Visit - RN", "SNV - Wound Care",
                        "SN  Wound Care", "SN Labs", "SN Evaluation"
                    ]);
                },
                sorter: "number"
            },
            {
                title: "PT Visits Episode Total",
                field: "pt_visits_total",
                headerVertical: true,
                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    return this.countVisitsByDescription(visits, [
                        "PT Visit", "PT Re-evaluation w/Supervisory Visit",
                        "PT Evaluation", "PT Re-Evaluation"
                    ]);
                },
                sorter: "number"
            },
            {
                title: "OT Visits Episode Total",
                field: "ot_visits_total",
                headerVertical: true,
                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    return this.countVisitsByDescription(visits, [
                        "OT Visit", "OT Evaluation", "OT Re-Evaluation w/Supervisory Visit",
                        "OT Re-Evaluation"
                    ]);
                },
                sorter: "number"
            },
            {
                title: "ST Visits Episode Total",
                field: "st_visits_total",
                headerVertical: true,
                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    return this.countVisitsByDescription(visits, [
                        "ST Visit", "ST Evaluation", "ST Re-Evaluation"
                    ]);
                },
                sorter: "number"
            },
            {
                title: "HHA Visits Episode Total",
                field: "hha_visits_total",
                headerVertical: true,
                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    return this.countVisitsByDescription(visits, ["HHA Visit"]);
                },
                sorter: "number"
            },
            {
                title: "In Home Visits Episode Total",
                field: "in_home_visits_total",
                headerVertical: true,
                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    return visits.length; // Assuming all visits are in-home
                },
                sorter: "number"
            },
            // VBH columns would require additional data not present in the current structure
            // SMS and Calls columns would also require additional data

            {
                title: "Total Touchpoints (Visits+VBH)",
                field: "total_touchpoints",
                headerVertical: true,
                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    // Assuming VBH touchpoints are included in visits. If not, you'll need to add them separately
                    return visits.length;
                },
                sorter: "number"
            },
            {
                title: "Date of Transfer OASIS in episode",
                field: "transfer_oasis_date",

                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    const transferVisit = visits.find(v => v.visit_description.toLowerCase().includes("oasis") && v.visit_description.toLowerCase().includes("transfer"));
                    return transferVisit ? format(parseISO(transferVisit.visit_date), "MM/dd/yyyy") : "";
                },
                sorter: "date"
            },
            {
                title: "Date of Discharge",
                field: "discharge_date",
                formatter: (cell) => {
                    const visits = cell.getRow().getData().visits || [];
                    const dischargeVisit = visits.find(v => v.visit_description.toLowerCase().includes("oasis") && v.visit_description.toLowerCase().includes("discharge"));
                    return dischargeVisit ? format(parseISO(dischargeVisit.visit_date), "MM/dd/yyyy") : "";
                },
                sorter: "date"
            },













            //{
            //    title: "SOC Date",
            //    field: "current_soc",
            //    formatter: function (cell, formatterParams) {
            //        const value = cell.getValue();
            //        if (!value) {
            //            return "";
            //        }
            //        const rowData = cell.getRow().getData();
            //        const currentEpisodeStart = rowData.current_episode_start;
            //
            //        let formattedDate = "";
            //
            //        if (currentEpisodeStart) {
            //            const socDate = parseISO(value);
            //            const episodeStartDate = parseISO(currentEpisodeStart);
            //            const daysDiff = differenceInDays(episodeStartDate, socDate);
            //
            //            if (daysDiff >= 3) {
            //                formattedDate += '<span class="small-asterix">*</span> ';
            //                cell.getElement().style.fontWeight = "bold";
            //            }
            //        }
            //
            //        formattedDate += format(parseISO(value), "MM/dd/yyyy");
            //
            //        return formattedDate;
            //    },
            //
            //    sorter: "date",
            //    sorterParams: {
            //        alignEmptyValues: "bottom"
            //    }
            //},






            {
                name: "Primary Payor",
                fields: ["primary_payer"],
                field: "primary_payer",
                minWidth: 300
            },
            {
                title: "Primary Diagnosis",
                name: "PDX",
                minWidth: 280,
                tooltip: true,
                fields: ["primary_diagnosis"],
                field: "primary_diagnosis",
                formatter: function (cell) {
                    const value = cell.getValue();
                    return value ? value : "";
                }

            },
            {
                name: "Engagement Specialist",
                fields: ["engagement_specialist.last_name"],
                field: "engagement_specialist.last_name",
                minWidth: 100
            },
            {
                title: " Referral Source",
                field: "referral",
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getData();
                    let content = '';

                    if (rowData.referral_source) {
                        content += `${rowData.referral_source}`;
                    }
                    if (rowData.referral_source && rowData.ext_referral) {
                        content += '<span style="width:15px;"></span>';
                    }
                    if (rowData.ext_referral) {
                        content += `<strong style="margin-right:20px;">External Referral</strong> ${rowData.ext_referral}`;
                    }

                    return content;
                }
            },

            {
                name: "Internal Memo",
                fields: ["internal_memo"],
                field: "internal_memo",
                minWidth: 100,
                maxWidth: 350,
                formatter: "html",
            },
        ];

        this._filter_config = {
            collection: {
                name: "patient",
                auto_configure: false,
                depth: 3,
                configured: Promise.resolve(),
                fields: []
            },
            search_fields: [
                "first_name",
                "last_name",
                "status",
                "source",
                "mrn",
                "medicare_id",
                "referral_source",
                "ext_referral"
            ],
            field_rules: {
                directus_users: {
                    mode: "whitelist",
                    fields: [
                        "first_name",
                        "last_name",
                    ],
                }
            }
        };
        this.dropdown = null;
    }

    connectedCallback() {
        this.template = () => {

            return html`
                <style>
                    /* TODO */
                    .task-row:hover {
                        background-color: #f0f0f0;
                        cursor: pointer;
                    }
                </style>
                <div class="container-fluid" style="height: 100%;">
                    <div class="row" style="padding: 0 32px;">
                        <nav class="navbar" id="patient-insights-nav">
                            <div style="display: flex; flex-direction: column; align-items:left;">
                                
                                <app-filter 
                                    @filter_change=${e => this.handleFilterChange(e.detail)}
                                    style="
                                    margin-right: 10px;
                                    "
                                    .expanded=${true}
                                    .filter_state=${this.filter_state}
                                    .config=${this._filter_config}
                                    .enable_aggregate=${false}
                                    .disable_sort=${false}
                                    .show_filters=${true}
                                ></app-filter>
                            </div>
                            <div style="display: flex; flex-direction: column; align-items:left;">
                                
                                <app-filter-saved-views
                                    .screen=${"patient_insights"}
                                    @filter_change=${e => this.handleFilterChange(e.detail)}
                                    .collection_name=${"patient"}
                                    .filter_state=${this.filter_state}
                                ></app-filter-saved-views>
                            </div>
                            <app-filter-csv-export 
                                style="position: absolute; top: -55px; right: 10px;"
                                .filter_state=${this.filter_state}
                                .selected_columns=${this.selected_columns}
                                .config=${this._filter_config} 
                                .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        }
                    ]
                }
                }
                            ></app-filter-csv-export>
                        </nav> 
                    </div>
                    <div class="row" style="padding: 0 32px;" id="patient-insights-scroll-container">
                        <app-filter-data-view-server
                            @data=${e => this.data = e.detail}
                            .config=${this._filter_config}
                            .options=${this.options}
                            .table_columns=${this.table_columns}
                            .filter_state=${this.filter_state}
                            .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        }
                    ]
                }
                }
                            .selected_columns=${this.selected_columns}
                            @select-row=${e => this.handleSelectPatient(e.detail)}
                            style="padding: 0;"
                        ></app-filter-data-view-server>
                    </div>
                </div>
            `
        };

        this.render();
        this.init();

        this.addEventListener("select-row", (e) => {
            if (e.detail?.id) {
                this.handleSelectPatient(e.detail);
            }
        });
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    init() {
        let filter_state = ApplicationState.get('app.insights_patients.filter_state');
        let filter_element = this.querySelector('app-filter');
        filter_element.filter_state = filter_state;
        this.filter_state = filter_state;
        this.render();
    }

    async handleFilterChange(filter_state) {
        ApplicationState.set('app.insights_patients.filter_state', filter_state, { immutable: true });
        this.filter_state = filter_state;
        this.render();
    }

    async handleSelectPatient(patient) {
        //await navigate(`patients/${patient.id}/checkpoints`);
        let component = new AppPatientProfile();
        component.patient = patient;
        /** @type {import("../app-drawer").DrawerOptions} */
        let drawer_options = {
            placement: 'end',
            title: `${patient.first_name} ${patient.last_name}`,
            component,
            show_backdrop: false
        }
        this.dispatchEvent(
            new CustomEvent('show_drawer',
                {
                    bubbles: true,
                    composed: true,
                    detail: drawer_options
                })
        )
    }

}

customElements.define('scene-insights-patients-plus', SceneInsightsPatientsPlus);
